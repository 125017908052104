import request from '@/utils/request'

// 首页 数据概况
export function gk(query) {
    return request({
        url: '/up/up-device/home/statistics',
        method: 'get',
        params: query
    })
}

// 首页 数据趋势图
export function qst(query) {
    return request({
        url: '/up/up-device/home/trend',
        method: 'get',
        params: query
    })
}
