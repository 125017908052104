<template>
  <page-header-wrapper>
    <div class="box">
      <div class="box1">
        <div class="data-profiling">
          <div class="title">
            <div class="p">数据概况<div class="span">实时数据：{{ dateInfo }} <img src="@/assets/images/icon_9.png" @click="getInfo()"></div></div>
            <a-radio-group v-model="type">
              <a-radio-button :value="0">今日</a-radio-button>
              <a-radio-button :value="1">昨日</a-radio-button>
            </a-radio-group>
          </div>
          <div class="list">
            <div class="item">
              <img src="@/assets/images/icon_4.png" style="cursor: pointer;" @click="goRouter('/device/device4G')">
              <div class="tex">
                <div class="span1">设备连接数量</div>
                <div class="span2">{{ gkInfo.devConnect ? gkInfo.devConnect : '-' }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_3.png" style="cursor: pointer;" @click="goRouter('/appUser')">
              <div class="tex">
                <div class="span1">实际升级数量</div>
                <div class="span2">{{ gkInfo.devUpgrade ? gkInfo.devUpgrade : '-' }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_5.png" >
              <div class="tex">
                <div class="span1">待升级数量</div>
                <div class="span2">{{ gkInfo.devToBeUpgraded ? gkInfo.devToBeUpgraded : '-' }}</div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/images/icon_1.png" >
              <div class="tex">
                <div class="span1">设备总数</div>
                <div class="span2">{{ gkInfo.devTotal ? gkInfo.devTotal : '-' }}</div>
              </div>
            </div>
          </div>
          <div class="title title2">数据趋势图
            <a-range-picker style="width: 260px;" @change="onChange1" v-model="time"><a-icon slot="suffixIcon" type="calendar" /></a-range-picker>
          </div>
          <line_1 height="420px" :dataInfo="echartsData1" v-if="echartsData1.id"/>
        </div>
      </div>
    </div>
  </page-header-wrapper>
</template>

<script>
import { PageHeaderWrapper } from '@/components/ProLayout'
import { message } from 'ant-design-vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
import aMap from '@/components/AmapPoint/index'
import line_1 from '@/components/echarts/line/line_1'
import { gk, qst } from '@/api/pages/home'

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
    vueSeamlessScroll,
    aMap,
    line_1
  },
  data () {
    return {
      type: 0,
      dateInfo: '',
      gkInfo: {
        devConnect: 0,
        devToBeUpgraded: 0,
        devTotal: 0,
        devUpgrade: 0
      },
      time: '',
      startTime: '',
      endTime: '',
      echartsData1: {}
    }
  },
  watch: {
    type(n, o) {
      this.type = n
      this.getGk()
    }
  },
  computed: {
  },
  created () {
    this.getInfo()
  },
  mounted () {
  },
  methods: {
    goRouter(url) {
      this.$router.push({ path: url })
    },
    onChange1(date, dateString) {
      // console.log(date, dateString)
      this.startTime = new Date(dateString[0] + ' 00:00:00').getTime() / 1000
      this.endTime = new Date(dateString[1] + ' 23:59:59').getTime() / 1000
      this.echartsData1 = {}
      this.getQst()
    },
    getInfo() {
      const hide = message.loading('加载数据中...', 0)
      setTimeout(hide, 2500)

      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1)
      const day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
      const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
      const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
      const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
      this.dateInfo = `${year}-${month}-${day} ${hour}:${minute}:${second}`

      this.time = ''
      this.type = 0
      this.startTime = ''
      this.endTime = ''
      this.echartsData1 = {}

      this.getGk()
      this.getQst()
    },
    // 数据概况
    getGk () {
      gk({ type: this.type }).then(response => {
        this.gkInfo = response.data
      })
    },
    // 数据趋势图
    getQst () {
      qst({ startTime: this.startTime, endTime: this.endTime }).then(response => {
        const data = response.data
        const dwlist = []
        const list1 = []
        const list2 = []
        const list3 = []
        data.map(item => {
          dwlist.push(item.x)
          list1.push(item.devConnect)
          list2.push(item.devUpgrade)
          list3.push(item.devToBeUpgraded)
        })
        this.echartsData1 = {
          color: ['#FF7F00', '#007AFF', '#049504'],
          textStyle: {
            color: '#fff'
          },
          id: 'echarts1',
          dwlist: dwlist,
          line: [{
            name: '设备连接数',
            type: 'line',
            showSymbol: false,
            data: list1
          }, {
            name: '待升级数',
            type: 'line',
            showSymbol: false,
            data: list2
          }, {
            name: '实际升级数',
            type: 'line',
            showSymbol: false,
            data: list3
          }]
        }
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index.less";
  .box{
    width: 100%;
    padding: 0 24px;
  }
  .box1{
    width: 100%;
    display: flex;
    margin-bottom: 26px;
    .title{
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 22px;
      color: #333333;
      justify-content: space-between;
      .p{
        display: flex;
        align-items: center;
      }
      .span{
        height: 28px;
        border-radius: 14px;
        background: rgba(0, 122, 255, 0.06);
        padding: 0 12px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #007AFF;
        margin-left: 18px;
        img{
          width: 14px;
          height: 14px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    .data-profiling{
      width: 100%;
      height: 600px;
      background: #ffffff;
      border-radius: 26px;
      padding: 20px;
      position: relative;
      .list{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 28px 0;
        .item{
          // width: 16.6%;
          width: 20%;
          height: 52px;
          display: flex;
          align-items: center;
          img{
            flex-shrink: 0;
            width: 52px;
            height: 52px;
          }
          .tex{
            display: flex;
            flex-direction: column;
            padding: 0 16px;
            .span1{
              font-size: 12px;
              color: #666666;
            }
            .span2{
              font-size: 20px;
              color: #333333;
              font-weight: bold;
            }
          }
        }
      }
      .title2{
        width: calc(100% - 40px);
        position: absolute;
        z-index: 10;
      }
    }
  }
</style>
